// 枚举数据
export const enumData = [{ value: '', label: '' }]
export function getCurrentOrgKey(originData, val) {
    let r = []
    let fn = function (arr) {
        arr.forEach((item) => {
            if (item.title.indexOf(val) > -1) {
                r.push(item.key)
            }
            if (item.children && item.children.length) {
                fn(item.children)
            }
        })
    }
    fn(originData)
    return r
}
// 业务角色
export const enumBusinessRole = [
    { value: '甲方', label: '甲方' },
    { value: '总包', label: '总包' },
    { value: '监理', label: '监理' },
    { value: '三方', label: '三方' }
]

// 业务体系
export const enumBusinessSystem = [
    { value: '进度', label: '进度' },
    { value: '质量', label: '质量' },
    { value: '安全', label: '安全' },
    { value: '履职', label: '履职' }
]

// 进度业务类型
export const enumProgressBusinessType = [
    { value: '计划进度', label: '计划进度' }
]

// 质量业务类型
export const enumQualityBusinessType = [
    { value: '材料验收', label: '材料验收' },
    { value: '桩基验收', label: '桩基验收' },
    { value: '质量巡检', label: '质量巡检' },
    { value: '工序验收', label: '工序验收' },
    { value: '样板验收', label: '样板验收' },
    { value: '实测实量', label: '实测实量' },
    { value: '实测实量(三方)', label: '实测实量(三方)' },
    { value: '质量风险(三方)', label: '质量风险(三方)' }
]

// 安全业务类型
export const enumSafetyBusinessType = [
    { value: '重点设备安全', label: '重点设备安全' },
    { value: '安全巡检', label: '安全巡检' },
    { value: '安全验收', label: '安全验收' },
    { value: '安全作业', label: '安全作业' },
    { value: '安全培训', label: '安全培训' },
    { value: '安全投入', label: '安全投入' },
    { value: '安全风险(三方)', label: '安全风险(三方)' }
]

// 履职业务类型
export const enumPerformanceBusinessType = [
    { value: '人员出勤', label: '人员出勤' },
    { value: '系统使用', label: '系统使用' },
    { value: '管理行为(三方)', label: '管理行为(三方)' }
]

// 业务类型
export const enumBusinessType = [
    { value: '计划进度', label: '计划进度工序验收' },
    { value: '工序验收', label: '工序验收' },
    { value: '桩基验收', label: '桩基验收' },
    { value: '样板验收', label: '样板验收' },
    { value: '材料验收', label: '材料验收' },
    { value: '实测实量', label: '实测实量' },
    { value: '质量巡检', label: '质量巡检' },
    { value: '实测实量 - 三方', label: '实测实量 - 三方' },
    { value: '质量风险 - 三方', label: '质量风险 - 三方' },
    { value: '设备安全 - 巡检', label: '设备安全 - 巡检' },
    { value: '设备安全 - 验收', label: '设备安全 - 验收' },
    { value: '设备安全 - 预警', label: '设备安全 - 预警' },
    { value: '安全巡检 - 季度', label: '安全巡检 - 季度' },
    { value: '安全巡检 - 月度', label: '安全巡检 - 月度' },
    { value: '安全巡检 - 日常', label: '安全巡检 - 日常' },
    { value: '安全巡检 - 事故', label: '安全巡检 - 事故' },
    { value: '安全巡检', label: '安全巡检' },
    { value: '安全验收', label: '安全验收' },
    { value: '安全作业', label: '安全作业' },
    { value: '安全培训 - 资质', label: '安全培训 - 资质' },
    { value: '安全培训 - 教育', label: '安全培训 - 教育' },
    { value: '安全培训 - 班前', label: '安全培训 - 班前' },
    { value: '安全风险 - 三方', label: '安全风险 - 三方' },
    { value: '人员出勤', label: '人员出勤' },
    { value: '管理行为 - 三方', label: '管理行为 - 三方' }
]

// 评分排序
export const enumRateSort = [
    { value: 'desc', label: '评分降序' },
    { value: 'asc', label: '评分升序' }
]

// 分值影响排序
export const enumScoreInfluenceSort = [
    { value: 1, label: '默认' },
    { value: 2, label: '评分降序' },
    { value: 3, label: '评分升序' }
]

// 模型变更类型
export const enumModelChangetype = [
    { value: 0, label: '新增' },
    { value: 1, label: '删除' }
]

// 分屏设置
export const enumSplitScreenSet = [
    { value: 1, label: '一屏' },
    { value: 2, label: '四屏' },
    { value: 3, label: '九屏' }
    // { value: 4, label: '十六屏' }
]

// 组织机构类型
export const enumOrganType = [
    { value: 0, label: '集团公司' },
    { value: 1, label: '区域公司' },
    { value: 2, label: '项目公司' },
    { value: 3, label: '工地' }
]

// 提交状态
export const enumSubmitStatus = [
    { value: 0, label: '未提交' },
    { value: 1, label: '已提交' }
]

// 时间阈值
export const enumTimeThreshold = [
    { value: 0, label: '天' },
    { value: 1, label: '小时' }
]

// 处理状态
export const enumHandleStatus = [
    { value: 0, label: '未处理' },
    { value: 1, label: '已处理' }
]

// 启用状态
export const enumEnableStatus = [
    { value: 0, label: '禁用' },
    { value: 1, label: '启用' }
]

// 预警规则适用层级
export const enumApplicableLevel = [
    { value: 0, label: '通用' },
    { value: 1, label: '工地' }
]

// 添加状态
export const enumInsertStatus = [
    { value: 0, label: '未添加' },
    { value: 1, label: '已添加' }
]

// 组织架构层级
export const enumOrganStructureLevel = [
    { value: 0, label: '集团公司' },
    { value: 1, label: '区域公司' },
    { value: 2, label: '项目公司' },
    { value: 3, label: '工地' }
]

// 报表类型
export const enumReportType = [
    { value: 0, label: '年报' },
    { value: 1, label: '月报' },
    { value: 2, label: '周报' }
]

// 里程碑状态
export const enumMileStoneStatus = [
    { value: 0, label: '未开始' },
    { value: 1, label: '进行中' },
    { value: 2, label: '按期完成' },
    { value: 3, label: '逾期完成' },
    { value: 4, label: '逾期未完成' },
    { value: 10, label: '-' }
]

// 判定结果
export const enumJudgeResult = [
    { value: 1, label: '逾期未完成' },
    { value: 2, label: '逾期完成' },
    { value: 3, label: '不合格' },
    { value: 4, label: '未整改' },
    { value: 5, label: '/' },
    { value: 6, label: '缺失' },
    { value: 7, label: '异常' },
    { value: 8, label: '未处理' }
]

// 项目状态
export const enumProjectStatus = [
    { value: 1, label: '开工' },
    { value: 2, label: '停工' },
    { value: 3, label: '复工' },
    { value: 4, label: '结束' },
    { value: 5, label: '立项' },
    { value: 6, label: '专规' },
    { value: 7, label: '实施主体' },
    { value: 8, label: '竣工' },
    { value: 9, label: '已交付' }
]

// 是否整改
export const enumRectification = [
    { value: 0, label: '是' },
    { value: 1, label: '否' }
]

// 根据值获取文本
export function getLabelByValue(
    enumList = [],
    value = '',
    valueKey = 'value',
    labelKey = 'label'
) {
    return (enumList.find((ele) => ele[valueKey] === value) || {})[labelKey]
}
