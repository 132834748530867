<script>
	import PhotoShow from '@/components/PhotoShow'
	import { enumHandleStatus, getLabelByValue } from '@/utils/EnumUtils'
	// import CommonApi from '@/api/CommonApi'
	// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
	import moment from 'moment'
	export default {
		name: 'AlarmEventDetail',
		components: {
			PhotoShow
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			detailId: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				enumHandleStatus: enumHandleStatus,
				getLabelByValue: getLabelByValue,
				detailData: null,
				previewVisible: false,
				previewUrl: ''
			}
		},
		watch: {
			visible(nVal) {
				this.detailData = null
				if (nVal && this.detailId) {
					this.getAlarmEvenDetail()
				}
			}
		},
		methods: {
			getAlarmEvenDetail() {
				this.$api.getAlarmEvenDetail(this.detailId)
					.then((response) => {
						if (response.code == 200) {
							if (response.data.startTime && response.data.endTime) {
								let duration = moment(response.data.endTime).diff(
									moment(response.data.startTime),
									'hours'
								)
								if (duration == 0) {
									response.data.alarmDuration = '1小时内'
								} else if (duration >= 1 && duration < 24) {
									response.data.alarmDuration = duration + '小时'
								} else {
									response.data.alarmDuration = Math.floor(duration / 24) + '天'
								}
							} else {
								response.data.alarmDuration = '1小时内'
							}
							this.detailData = response.data
						} else {
							response.msg && this.$message.error(response.msg)
						}
					})
					.catch((err) => {
						err.message && this.$message.error(err.message)
					})
			},
			fileDownload(fileUrlStr) {
				// console.log(fileUrlStr)
				// this.$api.fileDownload(fileUrlStr)
				this.previewVisible = true
				this.previewUrl =this.picServer+ fileUrlStr
			},
			picPreview(picUrl) {
				this.previewVisible = true
				this.previewUrl = picUrl
			},
			btnCancel() {
				this.$emit('close')
			}
		},
		computed: {
            picServer() {
                return this.$store.state.picServer
            },
        },
	}
</script>

<template>
	<a-drawer
		title="预警事件详情"
		:width="400"
		:closable="false"
		:maskClosable="true"
		:visible="visible"
		@close="btnCancel"
	>
		<a-form
			:colon="false"
			:labelCol="{ span: 5 }"
			:wrapperCol="{ span: 19 }"
			:labelAlign="'left'"
		>
			<a-form-item label="预警名称">
				<label class="form-label" v-if="detailData">{{
					detailData.warnName
				}}</label>
			</a-form-item>
			<a-form-item label="事件描述">
				<label class="form-label" v-if="detailData">{{
					detailData.warnDesc
				}}</label>
			</a-form-item>
			<a-form-item label="预警类型">
				<label class="form-label" v-if="detailData">{{
					detailData.typeName
				}}</label>
			</a-form-item>
			<a-form-item label="预警等级">
				<label class="form-label" v-if="detailData">{{
					detailData.levelName
				}}</label>
			</a-form-item>
			<!-- <a-form-item label="事态等级">
				<label class="form-label" v-if="detailData">{{
					detailData.situationName
				}}</label>
			</a-form-item> -->
			<a-form-item label="组织架构">
				<label class="form-label" v-if="detailData">{{
					detailData.orgName
				}}</label>
			</a-form-item>
			<a-form-item label="预警时间">
				<label class="form-label" v-if="detailData">{{
					detailData.startTime
				}}</label>
			</a-form-item>
			<a-form-item label="预警时长">
				<label class="form-label" v-if="detailData">{{
					detailData.alarmDuration
				}}</label>
			</a-form-item>
			<a-form-item label="处置状态">
				<label class="form-label" v-if="detailData">{{
					getLabelByValue(enumHandleStatus, detailData.warnStatus)
				}}</label>
			</a-form-item>
			<a-form-item label="处置描述">
				<label class="form-label" v-if="detailData">{{
					detailData.disposeDesc
				}}</label>
			</a-form-item>
			<a-form-item label="处置文件">
				<label class="form-label" v-if="detailData && detailData.disposeFile">
					
					<a
						href="javascript:void(0)"
						@click="fileDownload(detailData.disposeFile)"
					>
					<span>
						{{ detailData.disposeFile.split('#')[1] }}
					</span>
						<i class="ali-icon ali-icon-action-download"></i>
					</a>
				</label>
				<label v-else class="form-label">-</label>
			</a-form-item>
			<a-form-item label="处置照片">
				<div class="photo-list" v-if="detailData && detailData.disposePic">
					<div
						class="list-item"
						v-for="(pic, index) in detailData.disposePic.split(',')"
						:key="index"
						@click="picPreview(picServer + pic.split('#')[0])"
					>
						<img :src="picServer + pic.split('#')[0]" alt="" />
					</div>
				</div>
				<label v-else class="form-label">-</label>
			</a-form-item>
			<a-form-item label="处置人">
				<label class="form-label" v-if="detailData">{{
					detailData.userName
				}}</label>
			</a-form-item>
			<a-form-item label="处置时间">
				<label class="form-label" v-if="detailData">{{
					detailData.disposeTime
				}}</label>
			</a-form-item>
		</a-form>
		<div class="close-button">
			<i class="ali-icon ali-icon-action-close" @click="btnCancel"></i>
		</div>
		<PhotoShow
			:visible="previewVisible"
			:picUrl="previewUrl"
			@cancel="previewVisible = false"
		></PhotoShow>
	</a-drawer>
</template>

<style lang="scss" scoped>
	.photo-list {
		display: inline-flex;
		flex-wrap: wrap;
		.list-item {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 64px;
			height: 64px;
			border-radius: 4px;
			border: 1px solid rgba(63, 114, 186, 0.5);
			margin: 0 8px 8px 0;
			padding: 8px;
			cursor: pointer;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
</style>
