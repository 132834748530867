<script>
import PhotoShow from "@/components/PhotoShow";
import { enumHandleStatus, getLabelByValue } from "@/utils/EnumUtils";
// import CollisionAlarmApi from '@/api/CollisionAlarmApi'
import moment from "moment";
import { Debounce } from "@/utils/OperateUtils";
export default {
  name: "AlarmEventHandle",
  components: {
    PhotoShow,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    detailId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: this.$form.createForm(this),
      enumHandleStatus: enumHandleStatus,
      getLabelByValue: getLabelByValue,
      detailData: null,
      fileList: [],
      picList: [],
      previewVisible: false,
      previewUrl: "",
      editLoading: false,
      submitLoading: false,
    };
  },
  watch: {
    visible(nVal) {
      this.editLoading = this.submitLoading = false;
      this.detailData = null;
      if (nVal && this.detailId) {
        this.getAlarmEvenDetail();
      } else {
        this.$nextTick(() => {
          this.form.setFieldsValue({
            disposeDesc: "",
          });
        });
      }
    },
  },
  methods: {
    beforeFileUpload(file) {
      let isLt50M = file.size / 1024 / 1024 <= 50;
      if (!isLt50M) {
        this.$message.error("上传文件大小不能超过50MB");
      }
      return isLt50M;
    },
    uploadFileChange(result) {
      this.fileList = result.fileList.filter(
        (item) => item.status && item.status != "error"
      );
      console.log(result);
      // this.$message.success('上传成功')
      // if (result.file.status == 'error') {
      // 	this.$message.error('服务器错误')
      // } else if (result.file.status == 'done') {
      // 	if (result.file.response[0].ret) {
      // 		this.$message.success('上传成功')
      // 	} else {
      // 		this.$message.error('上传失败')
      // 	}
      // }
    },
    beforePicUpload(file) {
      let fileTypeDict = ["image/jpeg", "image/png", "image/gif"];

      let isFile = fileTypeDict.includes(file.type);
      let isLt50M = file.size / 1024 / 1024 <= 50;

      if (!isFile) {
        this.$message.warning("只支持JPG、JPEG、PNG、GIF格式图片");
      } else if (!isLt50M) {
        this.$message.warning("图片大小不能超过50MB");
      }
      return isFile && isLt50M;
    },
    uploadPicChange(result) {
      console.log(result);
      this.picList = result.fileList.filter(
        (item) => item.status && item.status != "error"
      );
      console.log(this.picList);
      // if (result.file.status == 'error') {
      // 	this.$message.error('服务器错误')
      // } else if (result.file.status == 'done') {
      // 	if (result.file.response[0].ret) {
      // 		this.$message.success('上传成功')
      // 	} else {
      // 		this.$message.error('上传失败')
      // 	}
      // }
    },
    picPreview(file) {
      console.log(file);
      this.previewVisible = true;
      this.previewUrl = file.response.data ? file.thumbUrl : "";
    },
    getAlarmEvenDetail() {
      this.$api
        .getAlarmEvenDetail(this.detailId)
        .then((response) => {
          if (response.code == 200) {
            if (response.data.startTime && response.data.endTime) {
              let duration = moment(response.data.endTime).diff(
                moment(response.data.startTime),
                "hours"
              );
              if (duration == 0) {
                response.data.alarmDuration = "1小时内";
              } else if (duration >= 1 && duration < 24) {
                response.data.alarmDuration = duration + "小时";
              } else {
                response.data.alarmDuration = Math.floor(duration / 24) + "天";
              }
            } else {
              response.data.alarmDuration = "1小时内";
            }
            this.detailData = response.data;
            let disposeFile = [];
            let disposePic = [];
            if (response.data.disposeFile) {
              let file = response.data.disposeFile.split("#");
              disposeFile = [
                {
                  uid: file[0],
                  name: file[1],
                  status: "done",
                  response: [
                    {
                      ret: true,
                      info: {
                        md5: file[0],
                      },
                    },
                  ],
                },
              ];
            }
            if (response.data.disposePic) {
              let picList = response.data.disposePic.split(",");
              let picArr = picList.map((item) => item.split("#"));

              disposePic = picArr.map((pic) => ({
                uid: pic[0],
                thumbUrl: pic[0].includes("http")
                  ? pic[0]
                  : this.$store.state.picServer + pic[0],
                name: pic[1],
                status: "done",
                url: "/zimg/" + pic[0],
                response: [
                  {
                    ret: true,
                    info: {
                      md5: pic[0],
                    },
                  },
                ],
              }));
            }
            this.$nextTick(() => {
              this.form.setFieldsValue({
                disposeDesc: response.data.disposeDesc || "",
              });
              this.fileList = disposeFile;
              this.picList = disposePic;
            });
          } else {
            response.msg && this.$message.error(response.msg);
          }
        })
        .catch((err) => {
          err.message && this.$message.error(err.message);
        });
    },
    // 0 - 保存，1 - 提交
    btnConfirm: Debounce(function (type) {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          let disposeFile = "";
          let disposePic = "";
          if (this.fileList.length && this.fileList[0].response.data) {
            disposeFile =
              this.fileList[0].response.data.codeUrl +
              "#" +
              this.fileList[0].name;
          }
          let picArr = [];
          console.log(this.picList);
          this.picList.length &&
            this.picList.forEach((item) => {
              if (item.response.data) {
                picArr.push(item.response.data.codeUrl + "#" + item.name);
              }
            });
          disposePic = picArr.toString();
          if (type) {
            this.submitLoading = true;
            this.$api
              .submitAlarmEvent({
                id: this.detailData.id,
                disposeDesc: values.disposeDesc,
                disposeFile: disposeFile,
                disposePic: disposePic,
              })
              .then((response) => {
                if (response.code == 200) {
                  this.$message.success("提交成功");
                  this.$emit("ok");
                } else {
                  response.msg && this.$message.error(response.msg);
                }
                this.submitLoading = false;
              })
              .catch((err) => {
                err.message && this.$message.error(err.message);
                this.submitLoading = false;
              });
          } else {
            this.editLoading = true;
            this.$api
              .saveAlarmEvent({
                id: this.detailData.id,
                disposeDesc: values.disposeDesc,
                disposeFile: disposeFile,
                disposePic: disposePic,
              })
              .then((response) => {
                if (response.code == 200) {
                  this.$message.success("保存成功");
                  this.$emit("ok");
                } else {
                  response.msg && this.$message.error(response.msg);
                }
                this.editLoading = false;
              })
              .catch((err) => {
                err.message && this.$message.error(err.message);
                this.editLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    }),
    btnCancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<template>
  <a-drawer
    class="edit-drawer"
    title="预警事件处置"
    :width="400"
    :closable="false"
    :maskClosable="true"
    :visible="visible"
    @close="btnCancel"
  >
    <a-form
      :form="form"
      :colon="false"
      :labelCol="{ span: 5 }"
      :wrapperCol="{ span: 19 }"
      :labelAlign="'right'"
    >
      <a-form-item label="预警名称">
        <label class="form-label" v-if="detailData">{{
          detailData.warnName
        }}</label>
      </a-form-item>
      <a-form-item label="事件描述">
        <label class="form-label" v-if="detailData">{{
          detailData.warnDesc
        }}</label>
      </a-form-item>
      <a-form-item label="预警类型">
        <label class="form-label" v-if="detailData">{{
          detailData.typeName
        }}</label>
      </a-form-item>
      <a-form-item label="预警等级">
        <label class="form-label" v-if="detailData">{{
          detailData.levelName
        }}</label>
      </a-form-item>
      <!-- <a-form-item label="事态等级">
        <label class="form-label" v-if="detailData">{{
          detailData.situationName
        }}</label>
      </a-form-item> -->
      <a-form-item label="组织架构">
        <label class="form-label" v-if="detailData">{{
          detailData.orgName
        }}</label>
      </a-form-item>
      <a-form-item label="预警时间">
        <label class="form-label" v-if="detailData">{{
          detailData.startTime
        }}</label>
      </a-form-item>
      <a-form-item label="预警时长">
        <label class="form-label" v-if="detailData">{{
          detailData.alarmDuration
        }}</label>
      </a-form-item>
      <a-form-item label="处置状态">
        <label class="form-label" v-if="detailData">{{
          getLabelByValue(enumHandleStatus, detailData.warnStatus)
        }}</label>
      </a-form-item>
      <a-form-item label="处置描述">
        <a-input v-decorator="['disposeDesc']" />
      </a-form-item>
      <a-form-item label="处置文件">
        <a-upload
          name="file"
          action="/api/work-site/personnel/upload/uploadFile.do"
          :file-list="fileList"
          :beforeUpload="beforeFileUpload"
          @change="uploadFileChange"
        >
          <a-button v-if="!fileList.length">上传</a-button>
        </a-upload>
      </a-form-item>
      <a-form-item label="处置照片">
        <a-upload
          name="file"
          accept=".gif,.jpg,.jpeg,.png"
          action="/api/work-site/personnel/upload/uploadFile.do"
          list-type="picture-card"
          :file-list="picList"
          :beforeUpload="beforePicUpload"
          @preview="picPreview"
          @change="uploadPicChange"
        >
          <i
            v-if="picList.length < 5"
            class="ali-icon ali-icon-action-upload-picture upload-picture"
          ></i>
          <!-- <a-icon
						class="upload-picture"
						type="plus"
						v-if="picList.length < 5"
					/> -->
        </a-upload>
      </a-form-item>
    </a-form>
    <div class="close-button">
      <i class="ali-icon ali-icon-action-close" @click="btnCancel"></i>
    </div>
    <div class="submit-button">
      <a-button :style="{ marginRight: '15px' }" @click="btnCancel">
        关闭
      </a-button>
      <a-button
        :loading="editLoading"
        :disabled="submitLoading"
        :style="{ marginRight: '15px' }"
        type="primary"
        @click="btnConfirm(0)"
      >
        保存
      </a-button>
      <a-button
        :loading="submitLoading"
        :disabled="editLoading"
        type="primary"
        @click="btnConfirm(1)"
      >
        提交
      </a-button>
    </div>
    <PhotoShow
      :visible="previewVisible"
      :picUrl="previewUrl"
      @cancel="previewVisible = false"
    ></PhotoShow>
  </a-drawer>
</template>

<style lang="scss" scoped>
.edit-drawer {
  /deep/ {
    .ant-drawer-body {
      max-height: calc(100% - 116px);
    }
  }
}
.upload-picture {
  font-size: 24px;
  color: #93bbf3;
}
</style>
